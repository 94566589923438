export default [
  {
    link: '/',
    text: 'Home'
  },
  {
    link: '/about/',
    text: 'About'
  },
  {
    link: '/contact/',
    text: 'Contact'
  }
];
