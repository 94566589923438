import { Spring, Trail, animated, config } from 'react-spring';

import React from 'react';
import socialLinks from '../data/social-links';

export default class Footer extends React.Component {
  constructor(props) {
    super(props);
  }

  isHome = () => {
    return this.props.location && this.props.location.pathname === '/';
  };

  render() {
    return (
      <footer className="flex flex-col-reverse md:flex-row items-center justify-between container mx-auto p-4 text-sm text-grey-400">
        <Spring
          native
          config={{ ...config.gentle, delay: 2500 }}
          immediate={!this.isHome()}
          from={{ opacity: 0, transform: 'translate3d(0, 20px, 0)' }}
          to={{ opacity: 1, transform: 'translate3d(0, 0px, 0)' }}
        >
          {props => (
            <animated.small className="md:self-end mt-2 md:mt-0" style={props}>
              &copy; 2019, Spade &amp; Sparrow
            </animated.small>
          )}
        </Spring>

        <ul className="flex list-reset m-0">
          <Trail
            native
            config={{ ...config.gentle, delay: 2500 }}
            immediate={!this.isHome()}
            items={socialLinks}
            keys={item => item.link}
            from={{ opacity: 0, transform: 'translate3d(0, 20px, 0)' }}
            to={{ opacity: 1, transform: 'translate3d(0, 0px, 0)' }}
          >
            {item => props => (
              <animated.li className="m-0" style={props}>
                <a href={item.link}>
                  <svg
                    aria-hidden="true"
                    data-prefix="fab"
                    data-icon={item.dataIcon}
                    className="fill-current text-grey-300 hover:text-grey-500 inline-block h-5 ml-3"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox={item.viewBox}
                  >
                    <path fill="currentColor" d={item.path} />
                  </svg>
                </a>
              </animated.li>
            )}
          </Trail>
        </ul>
      </footer>
    );
  }
}
